import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MainNavbar = () => {
  return (
    <Navbar expand="lg" style={{ backgroundColor: "#D9D9D9" }}>
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img 
            src={"https://i.imgur.com/TsJyIFt.png"} 
            width="50" 
            height="auto" 
            alt="Crutcher Tutoring Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Link as={Link} to="/aboutme" style={{ color: "#0C9212" }}>
              About Me
            </Nav.Link>
            <Nav.Link as={Link} to="/resources" style={{ color: "#0C9212" }}>
              Resources
            </Nav.Link>
            <Nav.Link 
              href="https://docs.google.com/forms/d/e/1FAIpQLSdAZRl2jemQvTEBdrkFIdsmx-Rbq7__foMfmks5h3VifuLAKQ/viewform?usp=sf_link"
              style={{ color: "#0C9212" }}
            >
              Book a Session
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar; 