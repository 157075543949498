import "./App.css";
import Home from "./Components/Home";
import Math from './Components/Math'
import "bootstrap/dist/css/bootstrap.min.css";
import MainNavbar from "./Components/Navigation/MainNavbar";

import {
  BrowserRouter as Router,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Resources from "./Components/Resources/Resources";
import AboutMe from "./Components/AboutMe";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path="/Resources" element={<Resources />}/>
        <Route path="/Math" element={<Math/>}/>
        <Route path="/aboutme" element={<AboutMe/>}/>
      </Route>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

const Root = () => {
  return (
    <div>
      <MainNavbar />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default App;
